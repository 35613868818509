import styles from './Footer.module.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { withTranslation } from 'next-i18next'
import tenantCmsObject from '@/entities/tenantCms/interface'
import FooterMenu from '@/components/template/footer/FooterMenu'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'
import BrandLinks from '@/themes/kultour/components/template/footer/BrandLinks'
import FooterAddress from '@/components/template/footer/FooterAddress'
import { buildMenuCols } from '@/components/template/footer/lib'
import Icon from '@/components/elements/Icon'

interface FooterProps {
  cms: tenantCmsObject
}

const Footer = ({ cms }: FooterProps) => {
  const menuCols = buildMenuCols(cms.categories, 4)

  return (
    <>
      <footer className={styles.footer}>
        <Container>
          <Row>
            <Col xs={12} sm={{ span: 8, offset: 2 }} md={{ span: 12, offset: 0 }} className={'px-4 px-sm-2'}>
              <Row>
                {menuCols.map((col, index: number) => {
                  return (
                    <Col md={3} lg={2} className={'p-0 p-md-2'} key={index}>
                      {col.map((menu: any, index: number) => {
                        return <FooterMenu title={menu.title} items={menu.items} key={index} />
                      })}
                    </Col>
                  )
                })}

                <Col xs={12} className={`${styles.divider} mt-0 mt-md-1 d-lg-none`} />

                <Col md={{ span: 12 }} lg={{ span: 3, offset: 1, order: 'first' }}>
                  <FooterAddress />
                  <p className="mb-2">
                    {cms.config?.socialMediaSettings?.facebookUrl && (
                      <a
                        href={cms.config.socialMediaSettings.facebookUrl}
                        className={styles.socialLink}
                        target={'_blank'}
                        rel="noreferrer"
                        data-tr-click="footer:social_media_icon:click"
                        data-tr-social_media_platform="facebook"
                      >
                        <span className={'visually-hidden-focusable'}>Facebook</span>
                        <Icon name="facebook" className={styles.icon} />
                      </a>
                    )}
                    {cms.config?.socialMediaSettings?.instagramUrl && (
                      <a
                        href={cms.config.socialMediaSettings.instagramUrl}
                        className={styles.socialLink}
                        target={'_blank'}
                        rel="noreferrer"
                        data-tr-click="footer:social_media_icon:click"
                        data-tr-social_media_platform="instagram"
                      >
                        <span className={'visually-hidden-focusable'}>Instagram</span>
                        <Icon name="instagram" className={styles.icon} />
                      </a>
                    )}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className={styles.divider} />
              </Row>
              <BrandLinks />
              <Row>
                <Col className={styles.divider} />
              </Row>
              <Row>
                <Col xs={{ span: 12, order: 'last' }} md={{ span: 3, order: 'first' }} lg={{ span: 3, offset: 1 }} className={'mb-3 mt-3 mt-md-0'}>
                  <small>&copy; {new Date().getFullYear()} Surprise Kultour AG</small>
                </Col>
                <Col className={`${styles.trustLogos}`}>
                  <a
                    target={'_blank'}
                    href="https://www.srv.ch/"
                    rel="noreferrer"
                    data-tr-click="footer:trust_icon:click"
                    data-tr-trust_icon_name="SRV"
                  >
                    <ThemeImage src={'images/logos/logo-srv.png'} height={36} alt="SRV Logo" theme={'kultour'} />
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.astag.ch/"
                    rel="noreferrer"
                    data-tr-click="footer:trust_icon:click"
                    data-tr-trust_icon_name="ASTAG"
                  >
                    <ThemeImage src={'images/logos/logo-astag.png'} height={34} alt="ASTAG Logo" theme={'kultour'} />
                  </a>
                  <a
                    target={'_blank'}
                    href="https://www.garantiefonds.ch/"
                    rel="noreferrer"
                    data-tr-click="footer:trust_icon:click"
                    data-tr-trust_icon_name="Reisegarantie"
                  >
                    <ThemeImage src={'images/logos/logo-reisegarantie-white.png'} height={38} alt="Reisegarantie Logo" theme={'kultour'} />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default withTranslation(['common', 'theme'])(Footer)
