import styles from '@/components/template/footer/FooterAddress/FooterAddress.module.scss'

const FooterAddress = () => {
  return (
    <div className={styles.root}>
      <p className="mb-4">
        <span className={styles.title}>SURPRISE KULTOUR AG</span>
        Rudolfstrasse 37
        <br />
        8400 Winterthur
      </p>
      <p className="mb-4">
        <span className={styles.country}>CH</span>
        <a href="tel:0041522351000" data-tr-click="footer:contact_phone:click">
          +41 (0)52 235 10 00
        </a>
        <br />

        <span className={styles.country}>D</span>
        <a href="tel:004977329505135" data-tr-click="footer:contact_phone:click">
          +49 (0)7732 950 51 35
        </a>
        <br />

        <a href="mailto:info@kultour.ch" data-tr-click="footer:contact_email:click">
          info@kultour.ch
        </a>
      </p>
    </div>
  )
}

export default FooterAddress
